// UseCasesSection.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons';

const useCases = [
    'Time tracking in construction sites',
    'Gym turnstyle customers recognition',
    'School staff time tracking',
    'Warehouse shifts and time tracking',
    'Time tracking and attendance management',
    'Access control and security',
    'Emergency evacuation management',
    'Employee self-service portal',
    // Add more use cases as needed
];

const UseCasesSection = () => {
    return (
        <div className="mt-20 shadow-md p-8 bg-blue-100 rounded-3xl">
            <h2 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
                Our customers <mark className="px-2 text-white bg-blue-600 rounded dark:bg-blue-500">business cases</mark>:
            </h2>
            <div className="text-lg font-normal text-gray-600 lg:text-xl dark:text-gray-400">
                <ul className="flex flex-wrap">
                    {useCases.map((item, index) => {
                        return (
                            <li key={index} className="w-1/2 mb-4">
                                <FontAwesomeIcon icon={faHandPointRight} className="mr-6" />
                                {item}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default UseCasesSection;
