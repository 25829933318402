import { React, useState } from "react"
import { navigate } from 'gatsby';
//import Button from './Button';
import { loadStripe } from "@stripe/stripe-js"


let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51JApL9JFfOivkS1yf9LgbjUg0fmR7FOcrgmnqGL5ATD9kqJbn1SxEJwc2sxwS4o2mojd4rRKFYXBum6dauSL6buT00ZPKUqRNF")
    //stripePromise = loadStripe('pk_test_51JApL9JFfOivkS1yXMIVUaQqZf8PPrr5bU2r6kHZ6NujEOecNznGfKLAv5IZ47ZI49LCQ2CNgDFFzP3mCmFR8ThS00ZWa5Emna')
  }
  return stripePromise;
}

const Checkout = ( { item, text = 'Order now' } ) => {
  console.log(item);
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    event.preventDefault();
    setLoading(true);
    if( item.paymentType === 'card') {
      const stripe = await getStripe()
      const { error } = await stripe.redirectToCheckout({
        mode: "subscription",
        lineItems: [{ price: item.priceId, quantity: 1 }],
        successUrl: `https://api.punch-in.co.uk/success?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `https://api.punch-in.co.uk/cancel`,
      })

      if (error) {
        console.warn("Error:", error)
        setLoading(false)
      }
    } else{
      navigate('/success', {
        state: {
          type: 'cash',
          maxUsers: item.maxUsers,
          price: item.priceId
        }
      })
    }

  }

  return (
      <button
        disabled={loading}
        size="md"
        className="rounded-full mt-6 bg-primary hover:bg-primary-darker rounded text-white py-3 px-8"
        onClick={redirectToCheckout}
      >
        {text}
      </button>
  )
}

export default Checkout
