import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const MessageOverlay = ( {type='info', text, close}) => {
  //console.log(close, type, text);
  return type === 'error' ? (
    <div className="overlay-text overlay-error">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <strong> Error</strong>: {text || 'General error. Please retry or contact support.'}
        <div className=" items-center float-right">
          <button className="bg-white text-red-800 hover:bg-yellow-200 py-2 px-3 rounded" href="#" title="close" onClick={close}><FontAwesomeIcon icon={faTimes} /> close</button>
        </div>
    </div>
  ) : (
    <div className="overlay-text overlay-success">
      <FontAwesomeIcon icon={faCheckCircle} />
      <strong> Success</strong>: {text}
        <div className=" items-center float-right">
          <button className="bg-white text-green-600 hover:bg-yellow-200 py-2 px-3 rounded" href="#" title="close" onClick={close}><FontAwesomeIcon icon={faTimes} /> close</button>
        </div>
    </div>
  )
};

export default MessageOverlay;
