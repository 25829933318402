import { React, useState } from "react"
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints } from '@fortawesome/free-solid-svg-icons';

const HowItWorks = () => {
  return (
    <section id="howitworks" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold text-blue-700">How does it work</h2>
      </div>
      <div className="container mx-auto px-8">
        <div className="mt-10 justify-center m-auto">
          <div className="flex md:flex-row rounded-t-2xl flex-col bg-blue-200 justify-center md:text-left text-center">
            <div className="flex flex-col justify-center items-center relative">
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full  border-blue-300 border-dashed"></div>
              </div>
              <div className="rounded-full w-12 h-12 text-xl text-blue-100 bg-blue-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">1</div>
              <FontAwesomeIcon icon={faShoePrints} className="text-blue-700" size="5x" />
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-blue-300 border-dashed"></div>
              </div>
            </div>
            <div className="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-blue-200">
              <div className="text-xs uppercase font-bold text-blue-500">Step 1 - Subscribe</div>
              <div className="md:text-3xl text-xl font-bold text-blue-700">Your details</div>
              <div className="mt-4 text-blue-800">
                As in all services, we will need your details to sign you up. Choose the service you need and start the sign up process.
                We will need your credit card information and some details about yourself/ your company.
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col bg-purple-200 justify-center md:text-left text-center">
            <div className="flex flex-col justify-center items-center relative">
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-purple-300 border-dashed"></div>
              </div>
              <div className="rounded-full w-12 h-12 text-xl text-purple-100 bg-purple-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">2</div>
              <FontAwesomeIcon icon={faShoePrints} className="text-purple-700" size="5x" />
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-purple-300 border-dashed"></div>
              </div>
            </div>
            <div className="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-purple-200">
              <div className="text-xs uppercase font-bold text-purple-500">Step 2 - Creation</div>
              <div className="md:text-3xl text-xl font-bold text-purple-700">We build your environment</div>
              <div className="mt-4 text-purple-800">
                Once your personal and billing information has been entered, we will start building your environment.
                As each customer's environment runs on segregated  platform, you can rest assured that your data is is kept <strong>private</strong> and
                any outage on other environments will not impact yours. The build process should take about five to
                ten minutes; at the end, you will receive a notification e-mail confirming the creation of the environment.
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col bg-orange-200 justify-center md:text-left text-center">
            <div className="flex flex-col justify-center items-center relative">
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-orange-300 border-dashed"></div>
              </div>
              <div className="rounded-full w-12 h-12 text-xl text-orange-100 bg-orange-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">3</div>
              <FontAwesomeIcon icon={faShoePrints} className="text-orange-700" size="5x" />
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-orange-300 border-dashed"></div>
              </div>
            </div>
            <div className="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-orange-200">
              <div className="text-xs uppercase font-bold text-orange-500">Step 3 - Nearly ready!</div>
              <div className="md:text-3xl text-xl font-bold text-orange-700">Configure the back end</div>
              <div className="mt-4 text-orange-800">
                Your environment is ready! You can start setting up the back end right away. Here is
                where you will create your users, upload a picture for them and you are good to go.
                From here you can also view <strong>statistics</strong> and extract time in and out <strong>reports</strong>.
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col bg-green-200 justify-center md:text-left text-center">
            <div className="flex flex-col justify-center items-center relative">
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-green-300 border-dashed"></div>
              </div>
              <div className="rounded-full w-12 h-12 text-xl text-green-100 bg-green-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">4</div>
              <FontAwesomeIcon icon={faShoePrints} className="text-green-700" size="5x" />
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full  border-green-300 border-dashed"></div>
              </div>
            </div>
            <div className="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-green-200">
              <div className="text-xs uppercase font-bold text-green-700">Step 4 - Android application</div>
              <div className="md:text-3xl text-xl font-bold text-green-700">Download Punch-in app from our repository</div>
              <div className="mt-4 text-green-800">From the device you will be using for biometric face recognition, download and install
                the <a target="_blank" rel="noreferrer" href="https://repository.punch-in.co.uk/punch-in_latest.apk" title="Download Punch-in Android application for your tablet or phone">Punch-in application</a> from our repository, configure it with the information you have received once the environment was set up.</div>
            </div>
          </div>
        </div>
      </div>

    </section>)
}

export default HowItWorks;