import React, { useState, useEffect } from 'react';


const messages = [
  {
    title:'Face recognition clocking and attendance tool',
    content:'Start using face-recognition time and attendance management tool on site in about 5 minutes with no extra hardware to maintain, that works virtually anywhere, even without internet access.'
  },
  {
    title:'HR tool',
    content:'All the basic functionalities that small to medium businesses need: time off management, contracts, worked hours, appraisal, contractors and employees management. Payroll coming soon!'
  },
  {
    title:'Payroll',
    content:'Complete payroll management for small and medium businesses. Generate payslips, manage employees, and more.'
  },
  {
    title:'Task management',
    content:'Track and assign tasks to employees. Track worked hours on a specific task and for a given project, follow up on employees. Manage time spent for each project and site.'
  },
];


const Carousel = () => {
  const [index, setIndex] = useState(0);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransition(false); // Activate the transition class
      console.log(`transition off: ${transition} ${new Date()}`);

      // After 0.5 seconds, switch message and remove the transition class
      setTimeout(() => {
        setIndex(prevIndex => (prevIndex + 1) % messages.length);
        setTransition(true);
        console.log(`transition on: ${transition} ${new Date()}`);
      }, 2000); // This is the duration of your transition effect

    }, 5000); // Matches the CSS animation duration

    return () => clearInterval(interval);
  });

  return (

    <div className={`text-center lg:text-left w-full overflow-hidden whitespace-nowrap ${transition ? 'animate-marquee': ''}`}>
      <h1 className="text-3xl lg:text-4xl xl:text-5xl text-blue-700 font-bold leading-none ">
        {messages[index].title}
      </h1>
      <p className="text-xl lg:text-2xl mt-6 font-light">{messages[index].content}</p>
    </div>
  );
};

export default Carousel;
