import React from 'react';
import Card from './Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight} from '@fortawesome/free-solid-svg-icons'


const CustomerCard = ({ customer }) => (
  <Card className="mb-8">
    <div className="relative z-0">
      <FontAwesomeIcon icon={faQuoteRight} size="6x" className="text-blue-200 absolute top-0 right-0 z-0" />
    </div>
    <div className="relative z-10">
      <p className="text-xl font-semibold">{customer.title}</p>
      <p className="mt-6 subpixel-antialiased leading-relaxed">{customer.content}</p>
      <div className="flex items-center mt-8">
        <img
          className="w-12 h-12 mr-4 rounded-full"
          src={customer.customerImage}
          alt={customer.customerName}
        />
        <div>
          <p>{customer.customerName}</p>
          <p className="text-sm text-gray-600">{customer.customerTitle}</p>
        </div>
      </div>
    </div>
  </Card>
);

export default CustomerCard;
