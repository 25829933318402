import React from 'react';
import Footer from './Footer';
import Header from './Header';
import SEO from './seo';

const Layout = ({ title, description, image, article, children }) => {
  //console.log(title, description);
  return (
    <>
      <SEO title={title} description={description} image={image} article={article} />
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
