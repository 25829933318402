const customerData = [
  {
    title: 'Helped me increase invoice accuracy',
    content:
      'Before PunchIn, it was a lot of manual work with Excel spreadsheets. Now I have all my employees and partners\' timesheets at my fingertips.',
    customerName: 'Kris Apostolov',
    customerTitle: 'Director at APO Engineering Services',
    customerImage: 'https://placeimg.com/120/120/arch/sepia'
  },
  {
    title: 'We adopted it in our gym',
    content:
      'We needed a way to track how often people come to the gym to better understand costs and assess more precisely revenue per customer',
    customerName: 'Tony Monroe',
    customerTitle: 'Gym Manager',
    customerImage: 'https://placeimg.com/120/120/people/grayscale'
  },
  {
    title: 'Ideal for construction sites',
    content:
      'Sometimes we have hundreds of contractors at our site and we need to keep track in case of an emergency. Having adopted Punch-In has helped us make sure we are compliant and has increased our cost tracking capabilities as well as financial predictability.',
    customerName: 'Emma Ross',
    customerTitle: 'HR Manager',
    customerImage: 'https://placeimg.com/120/120/tech/grayscale'
  }
]

export default customerData;
