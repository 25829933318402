import { React, useState } from 'react';
import Checkout from '../components/Checkout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHippo, faDog, faKiwiBird, faEnvelope, faFileInvoice, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Card from '../components/Card';

const Payments = ({currentPrices}) => {
  console.log('currentPrices', currentPrices);

  const services = [
    {
      name: 'Light',
      icon: faKiwiBird,
      maxUsers: 10,
      price: 14.99,
      priceId: currentPrices.light,
      size: 'xs',
      paymentType: 'invoice'
    },
    {
      name: 'Medium',
      icon: faDog,
      maxUsers: 30,
      price: 26.99,
      priceId:  currentPrices.medium,
      size: '2x',
      paymentType: 'invoice'
    },
    {
      name: 'Large',
      icon: faHippo,
      maxUsers: 50,
      price: 49.99,
      priceId:  currentPrices.large,
      size: '3x',
      paymentType: 'invoice'
    },
    {
      name: 'Giant',
      icon: faHippo,
      maxUsers: 100,
      price: 76.99,
      priceId:  currentPrices.giant,
      size: '4x',
      paymentType: 'invoice'
    }
  ];

  const [payment, setPayment] = useState('invoice');

  return (
    <div className="container mx-auto text-center">
      <h2 className="text-3xl lg:text-5xl font-semibold text-blue-700">Prices</h2>
      <div className="flex flex-col lg:flex-row mx-3 mt-12">
        {
          services.map( (service, index) => (
            <div className="flex-1 px-3" key={index}>
              <Card className="mb-8">
                <FontAwesomeIcon icon={service.icon} className="iconPrimary" size={service.size} />
                <p className="font-semibold text-xl"> {service.name}</p>
                <p className="mt-4">
                  Up to <strong>{service.maxUsers} employees</strong>, no requirements, no commitments.<br />
                Pay monthly subscription. Cancel any time  <strong>*</strong>, no questions asked.
                </p>
                <p className="font-semibold text-xl">&pound; {service.price} <span className="text-sm"> </span></p>
                <div className="mt-20 ml-6">
                  <div className="flex items-center mb-1">

                    <label htmlFor={'invoice' + index} className="ml-2 py-2 md:py-1 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer hover:text-blue-700">
                      <input defaultChecked={true} id={'invoice' + index} type="radio" value={'invoice'} onClick={ () => setPayment('invoice')} name={'pay-type' + index} className="w-4 pr-1 text-blue-600 cursor-pointer" />
                       Pay via invoice <FontAwesomeIcon icon={faFileInvoice} className="iconPrimary" />
                   </label>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor={'card' + index} className="ml-2 py-2 md:py-1 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer hover:text-blue-700">
                      <input id={'card' + index} type="radio" value={'invoice'} onClick={ () => setPayment('card')} name={'pay-type' + index} className="w-4 pr-1 text-blue-600 cursor-pointer" />
                      Pay by card <FontAwesomeIcon icon={faCreditCard} className="iconPrimary" />
                    </label>
                  </div>
                </div>
                <Checkout item={ {...service, paymentType: payment } } />
              </Card>
            </div>
          ))
        }
      </div>
      <div className="flex flex-row mt-1 text-left mx-5">
        <p>For more than 100 users and/or if you need to implement bespoke solutions, integration with HR management system, barrier automation, special requests or any other query, please <AnchorLink className="whitespace-nowrap  tracking-normal" href="#contact" title="Get in touch"> <FontAwesomeIcon icon={faEnvelope} /> get in touch</AnchorLink></p>

      </div>
      <div className="flex flex-row mt-1">
        <p><strong>*</strong> We will not be able to refund the current month</p>
      </div>
    </div>
  )
}


export default Payments
