import { React, useState } from 'react';
import { Link } from "gatsby"
import AnchorLink from 'react-anchor-link-smooth-scroll';
//import LogoIcon from '../../svg/LogoIcon';
//import Button from '../Button';
//import AnchorButton from '../AnchorButton';
import logo from "../../assets/images/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faSignInAlt, faPhone, faEnvelope, faHeadset } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faClock } from '@fortawesome/free-solid-svg-icons'
// import { faCogs } from '@fortawesome/free-solid-svg-icons'
// import { faCoins } from '@fortawesome/free-solid-svg-icons'
// import { faComments } from '@fortawesome/free-solid-svg-icons'
// import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { isLoggedIn } from "../../services/auth";

const UpperHeader = () => (
  <div style={{ background: 'linear-gradient(90deg, #4199e1 10%, #e91e63)' }} className="text-white text-center md:text-right md:px-10 sm:px-1 py-2 md:tracking-wide font-bold">
    <div className="flex md:flex-row-reverse md:flex-wrap">
      <div className="flex-none px-2 md:px-5">
        <a className="text-white hover:text-blue-200 tracking-normal whitespace-nowrap" type="tel" href="tel:+44-1604-664-179" title="Call us for more information"><FontAwesomeIcon icon={faPhone} /> Call us <span className="hidden md:inline">at +44-1604-664-179</span></a>
      </div>
      <div className="flex-grow md:flex-none px-2 md:px-5">
        <AnchorLink className="whitespace-nowrap text-white hover:text-blue-200 tracking-normal" href="#contact" title="Get in touch"> <FontAwesomeIcon icon={faEnvelope} /> Contact us</AnchorLink>
      </div>
      <div className="flex-none px-2 md:px-5">
        <AnchorLink className="whitespace-nowrap text-white hover:text-blue-200 tracking-normal" href="#support" title="Our support team is ready to help you"> <FontAwesomeIcon icon={faHeadset} /> Help</AnchorLink>
      </div>
    </div>
  </div>
)

const Header = () => {
  const [hamburgerVisible, setHamburgerVisible] = useState(false);
  const toggleHamburger = () => {
    //// console.log('clicked!');
    setHamburgerVisible(!hamburgerVisible);
  }
  const me = isLoggedIn();
  console.log(me);

  const menuItems = [
    {
      title: 'Main features',
      anchor: '#features',
      id: 1
    },
    {
      id: 2,
      title: 'How does it work',
      anchor: '#howitworks'
    },
    {
      id: 3,
      title: 'Prices',
      anchor: '#pricing'
    },
    {
      id: 4,
      title: 'Testimonials',
      anchor: '#testimonials'
    },
    {
      id: 5,
      title: 'Docs',
      anchor: '#docs'
    },
  ]
  const h = (
    <header className="sticky fixed top-0 left-0 bg-white z-50 shadow-lg">
      <UpperHeader />
      <nav>
        <div className="flex items-center justify-between bg-slate-200 lg:mr-6 md:py-5 md:px-5">
          <div className="flex-shrink md:hidden">
            <button onClick={toggleHamburger}
              className={!hamburgerVisible ? 'hidden' : 'navbar-burger flex items-right rounded'}>
              <svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'>
                <title>Close menu</title>
                <path opacity='.6' fill='#CC0000' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z' />
              </svg>
            </button>
            <button onClick={toggleHamburger}
              className={hamburgerVisible ? 'hidden' : 'navbar-burger flex items-right p-3 rounded'}>
              <svg className="fill-current h-6 w-6 text-blue-600" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div className={hamburgerVisible ? 'hidden' : 'flex-grow-1'}>
            <div className="flex">
              <div className="w-auto md:px-8 md:py-4">
                <a href="/"><img className="w-auto resize-none" src={logo} alt="PunchIn Logo" /></a>
              </div>
              <div className="flex-shrink p-2 md:hidden">
                <AnchorLink className={`
                  btn
                  whitespace-no-wrap
                  text-xs
                  inline-block
                  font-bold
                  hover:bg-primary-darker
                  mr-1
                  p-1
                  text-white
                  bg-primary
                  rounded-md
              `} href="#pricing">Free trial</AnchorLink>
              </div>
            </div>
          </div>

          <div id="main-nav-mobile" className={hamburgerVisible ? 'flex-grow' : 'hidden'}>
            {
              menuItems.map((item) => {
                return (
                  <AnchorLink key={item.id} className="py-2 px-4 hover:text-purple-700 block lg:inline-block text-md  sm:hover:border-indigo-400 mx-2 focus:text-purple-700 hover:bg-gray-300 sm:hover:bg-transparent rounded-lg" href={item.anchor}>
                    {item.title}
                  </AnchorLink>
                )
              })
            }

          </div>
            {
              menuItems.map((item) => {
                return (
                  <div className="flex-grow-1  hidden md:block">
                  <AnchorLink key={item.id} className="px-4 hover:text-blue-800  block lg:inline-block text-md font-bold mx-2 p-4 hover:bg-blue-100 rounded-lg" href={item.anchor}>
                    {item.title}
                  </AnchorLink>
                  </div>
                )
              })
            }
            {me ? (<Link className="sm:m-50" to="/app/profile"><span className="px-4 hover:text-blue-800 block lg:inline-block text-md font-bold mx-2 focus:text-blue-500 hover:bg-blue-100 rounded-lg"><FontAwesomeIcon icon={faUserCircle} className="pr-1" />My Profile </span></Link>) : (<a href="/app/login" className="px-4 hover:text-blue-800 hover:bg-blue-100 p-4 rounded-lg"><FontAwesomeIcon icon={faSignInAlt} /><strong>Log in</strong></a>)}
            <div className="hidden md:block py-6">
              <AnchorLink className={`
                btn
                whitespace-no-wrap
                py-4
                px-2 lg:px-8 xl:px-12
                bg-primary
                hover:bg-primary-darker
                rounded
                font-bold
                text-white
            `} href="#pricing" title="Start your 1-month free trial">Free Trial</AnchorLink>
            </div>

        </div>
      </nav>
    </header>
  );
  return h;
}

export default Header;
